
h1, .h1 {font-size: 2rem; }
p {
  font-size: 15px;
  line-height: 25px; }
.mascot .card{background:none;border:none;}
.mascot img{width: 120px;margin: 0 auto;display: block;}
body {
  overflow-x: hidden !important;
  font-family: "Open Sans", sans-serif;
  background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/bg.jpg'); }

/* ===================================
Scrollbar
====================================== */
::-webkit-scrollbar { width: 8px;}
::-webkit-scrollbar-track {width: 11px;background:white;border-left: 0 solid white;border-right: 0 solid white;}
::-webkit-scrollbar-thumb {background: #185dad;width: 0;height: 25%;transition: .5s ease;cursor: pointer;}
.sx_msg_sec {
    height:380px;
    overflow-y: scroll;
    padding-bottom: 0px;
    margin-bottom: 30px;
}

.newgif {
    position: absolute!important;
    width: 45px!important;
    top: 0!important;
}
h1, h3, h5 {
  font-family: "Poppins", sans-serif; }

a {
  text-decoration: none; }

.modal-dialog {
  min-width: 750px; }

.modal-dialog .card:hover p {
  color: #000; }

.modal-dialog .modal-header {
  background: #da271d; }

.modal-dialog img {
  width: 100%;
  max-height:650px; }

.modal-dialog h1 {
  margin: 0px;
  text-align: center;
  font-size: 20px;
  color: #fff; }

.modal-dialog .carousel-control-prev-icon, .modal-dialog .carousel-control-next-icon {
  background-color: #256ffd; }

.modal-dialog .carousel-control-prev, .modal-dialog .carousel-control-next {
  top: 77px; }

.modal-dialog .modal-header .btn-close {
  background-color: #fff; }

.dropdown-menu li {
  text-align: left; }

.navbar-dark .navbar-toggler {
    color: rgb(0 0 0);
    border-color: #fffafa05;
}
.modal {
  background: #00000078;
}
.navbar-dark .navbar-toggler-icon {filter: invert(1);outline: none;}

.navbar-dark .navbar-toggler-icon:focus {outline: none;}

.navbar-dark .navbar-nav .nav-link {cursor: pointer;
  color: #085dad;
  font-weight: bold; }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #5f9019; }
.lumi_img img{height: 200px;width:100%;}
.btn-primary {
  color: #fff;
  background-color: #5f9019;
  border-color: #ffffff;
  width: 150px;
  text-align: center;
  margin: auto;
  /* display: block; */
  }

.slide-new {
  height: auto !important;
  width: 100%;
  padding: 0px !important;
  position: relative; }

.notice-color {
  background: #b5d7f9;
  }
  .notice-color .notice-sec {
    position: relative;
    height: 600px;
    overflow: hidden;
    }
    .notice-color .notice-sec-details {
      background-clip: border-box;
      box-shadow: 1px 2px 13px grey;
      margin-bottom: 25px;
      align-items: center;
      }
      .notice-color .notice-sec marquee .card {
    background: #ffffff78;
}
    .notice-color .notice-sec .card {
      background: #b5d7f9;
      margin-bottom: 10px;
      border-radius: 0px;
      padding: 0!important;
      border: none;
      }
      .notice-color .notice-sec .card:hover h3 {
        color: #000; }
      .notice-color .notice-sec .card h1 {
        text-align: center;
        font-weight: normal;
        margin-top: 10px;
        color: #000;
        }
        .new_menu_link_news{position: absolute;right: 5px;width: 37px;bottom: 0px;}
        .notice-color .notice-sec marquee{height: 600px;}
      .notice-color .notice-sec .card p {
        text-align: center;
        color: #000;
        margin-bottom: 5px;
        }
      .notice-color .notice-sec .card i {
        color: #5f9019;
        }
      .notice-color .notice-sec .card h5 {
        color: #000000;
        font-size: 17px;
        font-weight: bold;
        }
      .notice-color .notice-sec .card .text-muted {
        color: #000 !important;
        }
      .notice-color .notice-sec .card button {
        width: 35px;
        margin: auto;
        background: #2c71b5;
        border-radius: 20px;
        height: 35px;
        text-align: center;
        }
      .notice-color .notice-sec .card .date_news {
        font-size: 26px;
        padding-top: 10px;
        background: #b5d7f9;
        text-align: center;
        color: #324759;
        min-height: 90px; }
        .notice-color .notice-sec .card .date_news span {
          font-size: 15px;
          width: 100%;
          display: block; }
          .linktosec{display: flex;align-items: center;justify-content: center;}
.linktosec li{list-style-image: inherit !important;margin: 0px 20px;background: #cbcbcb;padding: 0px 20px;}
.linktosec li a{margin:0px;}
.alumni-reg {
  position: relative;
  top: 200px;
  right: 0px;
  z-index: 9;
  }
  .alumni-reg ul {
    margin: 0px;
    position: absolute;
    right: 0px;
    z-index: 9;
    }
    .alumni-reg ul li {
      display: block;
      }
      .alumni-reg ul li .online-reg img {
        width: 280px; }
      .alumni-reg ul li .online-reg h3 { 
        animation: bounce1 .5s linear infinite;
        font-size: 14px;
        padding: 10px 10px;
        border-radius: 5px 0px 0px 5px; }
        .alumni-reg ul li .online-reg h3 a {
         
          color: #fff;
          text-decoration: none; }
 
  .alumni-reg img.alumni {
    width: 160px;
    margin-top: 125px;
    }
  .alumni-reg img.tc {
    width: 200px;
    margin-top: 20px; }
  .alumni-reg img.campus {
    width: 85px;
    margin-top: 20px; }
      .alumni-reg img.sxmun-2022 {
    width: 200px;
    margin-top: 20px; 
    display: none;
    }

.logo-sec {
  background: #b5d7f9;
  padding: 5px 0;
  }
  .logo-sec .social img {
    width: 35px;
    background: #fff;
    padding: 5px;
    border-radius: 30px; }
  .logo-sec-contact {
    text-align: right; }
    .logo-sec-contact ul {
      margin: 0px;
      margin-bottom: 14px; }
      .logo-sec-contact ul li {
        color: #324759;
        display: block;
        text-align: center; }
        .logo-sec-contact ul li a {
          color: #324759;
          text-decoration: none; }
          .logo-sec-contact ul li a:hover {
            color: #000000; }
        .logo-sec-contact ul li i {
          color: #004d9b; }
      .logo-sec-contact ul:hover {
        color: #fff; }
  .logo-sec h3 {
    font-size: 17px;
    margin-top: 25px; }
    .logo-sec h3 a {
      color: #000;
      text-decoration: none; }
      .logo-sec h3 a:hover {
        color: #085dad; }
    .logo-sec h3 img {
      width: 39px;
      position: absolute;
      top: 63px; }
.career{width: max-content; background: #fa1200; margin: auto; padding: 0px 40px ; color: #fff; border-radius: 30px ;color:#fff;}
.career:hover{color:#ffeb00;}
.menu-sec {
  background: #fff;
  width: 100%;
  z-index: 99;
  color: #fff; }
  .menu-sec ul li i {
    color: #ffffff;
    background: #000;
    border-radius: 100%;
    padding: 4px 7px;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    }
  .menu-sec .navbar {
    margin: auto; }
    .menu-sec .navbar-collapse ul {
      text-align: center; }
  .menu-sec li:nth-child(1) i {
    background: #000; }
  .menu-sec li:nth-child(2) i {
    background: #fa1200; }
  .menu-sec li:nth-child(3) i {
    background: #7dc431; }
  .menu-sec li:nth-child(4) i {
    background: #4dd4f7; }
  .menu-sec li:nth-child(5) i {
    background: #2bb140; }
  .menu-sec li:nth-child(6) i {
    background: #87051e; }
  .menu-sec li:nth-child(7) i {
    background: #f90; }
  .menu-sec li:nth-child(8) i {
    background: #000; }
  .menu-sec li:nth-child(9) i {
    background: #f44335; }

.slider-sec {
  height: 600px; }
  .slider-sec .carousel-indicators {
    display: none; }
  .slider-sec .carousel {
    padding: 0px; }
    .slider-sec .carousel-caption {
      background: #b5d7f9;
      /* background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/slider-text.jpg'); */
      border-radius: 50px;
      border-bottom: 4px #fff solid;
      bottom: 105px;
      margin: 0 auto;
      width: max-content;
      padding: 15px 25px;
      color: #000; }
  .slider-sec img {
    height: 600px;
    object-fit: cover; }

.about-sec {
  background: #b5d7f9;
  padding-bottom: 20px;
  }
  .about-sec-data {
    background: #b5d7f9;
    padding: 35px 0px 50px 35px;
    position: relative;
    }
    .about-sec-data img {
      position: relative;
      height: 320px;
      object-fit: cover;
      width: 430px;
      right: 0px;
      margin-left: 15px; }
  .about-sec p {
    font-style: italic;
    font-size: 16px;
    line-height: 30px;
    color: #324759;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    height: 240px;
    }
  .about-sec h5 {
    color: #324759;
    font-size: 30px;
    font-weight: normal;
  }
  .about-sec-patron {
    background: #b5d7f9;
    padding: 35px;
    /* z-index: 999; */
    }
    .about-sec-patron img {
      width: 290px;
      height: auto;
      bottom: -61px;
      position: relative; }

.backgroundEffect {
  bottom: 0;
  height: 0px;
  width: 100%; }

.card {
  transition: all 0.5s ease;
  overflow: hidden;
  }
  .card:hover {
    transform: scale(1.025); }
    .card:hover h5 {
      color: #000;
      }
    .card:hover p {
      color: #000;
      }
    .card:hover:hover .backgroundEffect {
      bottom: 0;
      height: 320px;
      width: 100%;
      position: absolute;
      z-index: -1;
      background: #5f9019;
      animation: popBackground 0.3s ease-in;
      }
@keyframes popBackground {
  0% {
    height: 20px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%; }
  50% {
    height: 80px;
    border-top-left-radius: 75%;
    border-top-right-radius: 75%; }
  75% {
    height: 160px;
    border-top-left-radius: 85%;
    border-top-right-radius: 85%; }
  100% {
    height: 320px;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%; } }
.slider-bottom {
  margin-top: -30px; }
  .slider-bottom .card-body {
    text-align: center; }
    .slider-bottom .card-body i {
      color: white;
      background: #5f9019;
      padding: 2px 7px;
      font-size: 18px; }
    .slider-bottom .card-body h5 {
      text-align: center;
      font-size: 16px;
      padding: 0px; }
    .slider-bottom .card-body-text {
      text-align: center; }
  .slider-bottom .card img {
    width: 50px;
    height: 50px;
    margin: auto;
    display: block;
    margin-top: 10px; }
.kids-link img{width: 40px !important;margin: auto;display: block;}
.kids-link h5{text-align:center;font-size: 16px;font-weight: bold;color: #1e1e1e;}
.kids-link a{text-align:center;display: block;color: #000;}
.kids-link .card-body{padding:0px;}
.kids-link .card{background:none;border: none;margin-bottom: 5px;}

.myDiv {display: none;}
.myDiv .innertable table{margin:auto;width:95%;}
.selected_year {display: block;}
#ddlYear {border: solid 1px #d2d2d2;color: #001a52;width: 200px;height: 31px;padding: 5px;margin-top: 0px;background: #fff2ec;margin-bottom: 15px;}
.year_selction_sec{margin-bottom: 10px;}
.year_selction_sec select{margin:0px;}

.important-links{margin-top:50px;}
.important-links h1 {
  background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/title_bg.png') no-repeat center;
  line-height: 90px;
  color: #fff; }
.important-links .card {
  margin-bottom: 20px;
  border-radius: 0px;
  border: 0px; }
  .important-links .card-title {
    bottom: 20px;
    font-weight: normal;
    position: absolute;
    text-shadow: 5px 5px 8px #000; }
  .important-links .card-img-overlay {
    background: #0a100085;
    transition: all 0.5s ease-in-out; }
  .important-links .card-img-overlay:hover {
    background: #0a100029;
    transition: all 0.5s ease-in-out; }
  .important-links .card-img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    object-position: top;
    }
  .important-links .card-img.large {
    height: 520px; }
.important-links a{color:#fccc00;}
.important-links a:hover{color:#fff;}
.gallery {
  background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/news-sec.jpg') no-repeat left; }
  .gallery h1 {
    font-weight: 100;
    color: #fff;
    margin-bottom: 20px; }
  .gallery .btn-success {
    background-color: #ffffff;
    color: #198754; }
  .gallery .card:hover, .gallery .card p:hover {
    color: #fff; }
  .gallery .card .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 70px;
    background-color: #1b9ce3;
    color: white;
    position: absolute;
    top: 130px;
    transition: all ease; }
    .gallery .card .date img {
      width: 30px;
      height: 30px; }
  .gallery .card .date .day {
    font-size: 14px;
    font-weight: 600; }
  .gallery .card .date .month,
  .gallery .card .date .year {
    font-size: 10px; }
  .gallery .card .text-muted {
    color: #fff !important; }
  .gallery p {
    color: #fff;
    line-height: 35px;
    font-style: italic; }
  .gallery .admin {
    color: #000;
    margin-bottom: 0px; }

   .gallery h5 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    font-weight: 100;
    -webkit-box-orient: vertical;
    }
  .gallery .card-text {
    line-height: 25px;
    color: #000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-style: normal; }
  .gallery img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    }

.news {
  background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/news-sec.jpg') no-repeat right; }
  .news-section h1 {
    color: #000;
    margin-bottom: 15px; }
  .news-section .card p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }
  .news-section .card h5 {
    text-align: left; }
  .news-section img {
    height: 160px;
    object-fit: cover; }
  .news .birthday-sec h1 {
    color: #fff; }
  .news .birthday-sec .card {
    margin-top: 25px;
    padding-top: 15px; 
    margin: 0px 5px;
    width: 92% !important;
  }
    .news .birthday-sec .card:hover {
      background: #0a578f; }
    .news .birthday-sec .card h5 {
      text-align: center;
      font-weight: 100;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      }
    .news .birthday-sec .card p {
      text-align: center;
      margin-bottom: 5px; }
  .news .birthday-sec img {
    height: 170px;
    width: 170px !important;
    border-radius: 100%;
    margin: auto;
    object-fit: cover; }

.message-sec .message-sec-bg1 h1 {
  color: #324759; }
.message-sec .message-sec-bg1 h1 span {
  color: #956e00; }
.message-sec .message-sec-bg1 p {
  color: #324759; }
.message-sec .col-12 {
  position: relative; }
.message-sec .right-arrow {
  position: absolute;
  width: 55px;
  height: 60px;
  right: -24px;
  top: 130px;
  z-index: 9; }
  .message-sec .right-arrow-2:hover, .message-sec .right-arrow:hover{filter: contrast(200%);}
.message-sec .right-arrow-2 {
  position: absolute;
  width: 55px;
  height: 60px;
  left: 195px;
  top: 245px;
  z-index: 9; }
.message-sec h1 {
  color: #fff;
  font-weight: 100;
  font-size: 28px; }
.message-sec h1 span {
  color: #ffc400; }
.message-sec p {
  color: #fff;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical; }
  .message-sec a{
    padding: 0px;
}
.message-sec img {
  width: 100%;
  height: 300px;
  padding: 0px !important;     object-fit: cover;
    object-position: top;}
.message-sec-bg {
  background: #5f9019;
  height: 300px;
  transition: all 0.5s ease-in-out; }
  .message-sec-bg:hover {
    background: #0a100085;
    transition: all 0.5s ease-in-out; }
.message-sec-bg1 {
  background: #b5d7f9;
  height: 300px; }
  .message-sec-bg1:hover {
    background: #ecf6ff;
    transition: all 0.5s ease-in-out; }
.message-sec-bg2 {
  background: #5f9019;
  height: 300px; }
  .message-sec-bg2-bg2:hover {
    background: #0a100085;
    transition: all 0.5s ease-in-out; }
.message-sec .principal-msg {display:block;background: #b5d7f9;height: 300px;}
  .message-sec .principal-msg h1 {
    color: #324759; }
  .message-sec .principal-msg h1 span {
    color: #996300; }
  .message-sec .principal-msg p {
    color: #324759;
    -webkit-line-clamp: 8;
    }
  .message-sec .principal-msg:hover {
    background: #ecf6ff;
    transition: all 0.5s ease-in-out; }
.newscard{
  position: relative;
  overflow: hidden;
  width: 95% !important;
  margin: 0px 10px;
}
.events-sec h1 {
  color: #000;
  font-weight: 100;
  margin-bottom: 20px;
  }
.events-sec .btn-success {
  background-color: #ffffff;
  color: #198754; }
.events-sec .card:hover, .events-sec .card p:hover {
  color: #fff; }
.events-sec .card .date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 70px;
  background-color: #1b9ce3;
  color: white;
  position: absolute;
  top: 130px;
  transition: all ease; }
.events-sec .card .date .day {
  font-size: 14px;
  font-weight: 600; }
.events-sec .card .date .month,
.events-sec .card .date .year {
  font-size: 10px; }
.events-sec .card .text-muted {
  color: #fff !important; }
.events-sec p {
  color: #fff;
  line-height: 35px;
  font-style: italic; }
.events-sec .admin {
  color: #000;
  margin-bottom: 0px; }
.events-sec h5  {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    }
.events-sec .card-text {
  line-height: 25px;
  color: #000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal; }
     .events-sec .card .date img {
      width: 30px;
      height: 30px; }
.events-sec img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  background: white;
  }
.events-sec .newgif{margin:0px;}
.topopers-sec h1 {
  font-weight: 100;
  text-align: center; }
.topopers-sec h5 {
  text-align: center;
  font-weight: 100;
  font-size: 17px;
  }
.topopers-sec p {
  text-align: center; }
.topopers-sec img {
  width: 150px !important;
  height: 150px;
  margin: auto;
  margin-bottom: 10px;
  }
  .topopers-sec .card {
  width: 280px;
  height: 260px;
  overflow: hidden;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  border: none;
  background-color: #ffffff;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  box-shadow: 2px 2px 14px grey;
  margin: 0px 5px;
  width: 92% !important;
  }
  .eventcard {
    position: relative;
    overflow: hidden;
    width: 95% !important;
    margin: 0px 10px;
}
  .topopers-sec .card h4{position:absolute;font-size: 12px;background: #dc3545;padding: 5px;text-align: center;color: #fff;border-radius: 5px;}
  .topopers-sec .card::before {
    content: "";
    position: absolute;
    top: -50px;
    height: 0;
    width: 150%;
    background-color: #1369ce;
    z-index: -1;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    transition: all 0.5s ease-in-out; }
  .topopers-sec .card:hover::before {
    height: 100%;
    background-color: #5f9019; }
  .topopers-sec .card .card .img-container {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
    transition: all 0.5s ease-in-out; }
.footer-sec .card-body {
    text-align: left !important;
}
.footer-sec {
  background: #b5d7f9;
  position: relative; }
  .footer-sec .footer-btm-link {
    margin-bottom: 25px; }
    .footer-sec .footer-btm-link a {
      color: #324759;
      text-decoration: none;
      border-right: solid 1px #324759;
      padding-right: 6px;
      padding-left: 5px;
      line-height: 10px; }
      .footer-sec .footer-btm-link a:hover {
        color: #185dad;
        }
    .footer-sec .footer-btm-link ul {
      margin: 0px;
      text-align: center; }
      .footer-sec .footer-btm-link ul li {
        display: inline-block; }
  .footer-sec p {
    color: #fff;
    background: #5f9019;
    line-height: 25px;
    text-align: center;
    width: max-content;
    margin: 0 auto; }
    .footer-sec p a {
      color: #324759; }
    .footer-sec p a.link {
      color: #fff; }
  .footer-sec img {
    width: 45px;
    float: right;
    margin-top: 20px; }
  .footer-sec .card {
    background: none;
    border: none; }
    .footer-sec .card h5 {
      font-weight: 100;
      color: #324759;
      text-align: left; }
    .footer-sec .card p {
      color: #324759;
      text-align: left;
      background: none;
      margin: inherit;display:inherit; }

.inner_page {
  margin: 0px; }

.inner_slider {
  width: 100%;
  position: relative; }

.inner_slider_img {
  width: 100%;
  object-fit: cover;
  position: relative;
  height: 300px;
  }

.inner_slider .bread_cumb {
  background: #0000009c;
  height: 300px;
  padding: 50px 0px;
  width: 100%;
  position: absolute;
  top: 0px;
  margin: 0;
  }

.inner_slider .bread_cumb h2 {
  text-align: center;
  font-size: 30px;
  color: #ffeb00;
  margin-bottom: 10px;
  padding-top: 49px;
  letter-spacing: 2px;
  font-weight: bold; }

.inner_slider .bread_cumb h5 {
  text-align: center;
  color: #fff;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: normal; }

.inner_slider .bread_cumb h5 a {
  text-align: center;
  color: #fed403;
  font-size: 16px;
  text-decoration: none; }

.overlay_breadcmb {
  width: 250px !important;
  opacity: 0.6; }

.theme-heading {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px; }

.theme-heading span {
  position: relative; }

.theme-heading span .bi {
  color: #fff; }

.theme-heading span.colorW:after, .theme-heading span.colorW:before {
  background-color: #fed403;
  margin-bottom: 20px; }

.theme-heading span:after {
  content: "";
  width: 80px;
  height: 1px;
  background-color: #777;
  top: 50%;
  position: absolute;
  right: 30px;
  margin-top: -1px; }

.theme-heading span:before {
  content: "";
  width: 80px;
  height: 1px;
  background-color: #fed403;
  top: 50%;
  position: absolute;
  left: 30px;
  margin-top: -1px; }

.inner_cont {
  padding: 40px 50px;
  z-index: 9;
  min-height: 610px;
  background: #fff; }

.inner_cont.pray {
  background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/pray.png') no-repeat center; }

.inner_cont h2 {
  text-align: center;
  font-size: 27px;
  color: #185dad;
  margin-bottom: 25px;
  font-weight: 600;
  text-transform: uppercase; }

.inner_cont strong {
  color: #0070cf;
  }

.inner_cont h3 {
  line-height: 50px;
  font-size: 20px;
  color: #000000;
  font-weight: bold; }

.inner_cont p {
  color: #2d2d2d;
  margin-bottom: 15px;
  font-size: 17px;
  line-height: 40px;
  }

.inner_cont .large_font {
  /* font-size:18px; */
  /* line-height: 40px; */ }

.inner_cont ul {
  padding-left: 15px;
  margin-bottom: 20px; }

.inner_cont ul li {
  list-style-type: none;
  color: #525252;
  font-size: 18px;
  line-height: 40px;
  list-style-image: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/list.jpg'); }
  
  .inner_cont ol {
  padding-left: 15px;
  margin-bottom: 20px; }

.inner_cont ol li {
  /* list-style-type: none; */
  color: #525252;
  font-size: 17px;
  line-height: 30px;
  }

.inner_cont ul li a {
  color: #4e4e4e; }

  .inner_cont ul li button:hover {
    color: #fa1200;
}

.inner_img {
  float: left;
  width: 470px;
  margin-right: 35px;
  margin-bottom: 20px; }

.inner_cont img {
  object-fit: cover;
  object-position: top;
  }

.inner_img_right {
  float: right;
  width: 400px;
  height: 360px;
  margin-left: 35px;
  margin-bottom: 20px; }

.innertable {
  overflow: auto;
  color: #753400;
  margin-bottom: 30px; }

.innertable td {
  border: solid 1px #d9d9d9;
  padding: 5px;
  font-size: 15px;
  color: #333;
  line-height: 30px;
  text-align: center;
  }

.innertable td img {
  width: 75px;
  height: 65px; }

.innertable th {
  background: #099bca;
  border: solid 1px #fcf4ff;
  padding: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  line-height: 26px;
  }

.innertable tr:nth-child(even) {
  background-color: #fffce6; }

.inner_header {
  position: absolute;
  top: 0px;
  height: auto; }

.inner_header ul.menus, .menus1 {
  background: #000 !important; }

.inner_menu {
  background: #eee;
  padding: 10px; }

.inner_menu ul {
  margin: 0px; }

.inner_menu ul li {
  margin: 0px;
  list-style-type: none;
  text-transform: uppercase;
  border-bottom: solid 1px #e6e6e6;
  line-height: 35px;
  font-size: 13px !important;
  list-style-image: none;
  }

.inner_menu ul li a {
  color: #000;
  text-decoration: none; }

.inner_active {
  color: Red !important; }

.important_menu {
  background: #185dad;
  padding: 10px; }

.important_menu h3 {
  color: #fff; }

.important_menu ul {
  margin: 0px; }

.important_menu ul li {
  margin: 0px;
  list-style-type: none;
  list-style-image: none; }

.important_menu ul li a {
  color: #fff;
  text-decoration: none; }

.important_menu ul li a:hover {
  color: #fddfac; }

.important_menu .fas, .important_menu .fa {
  margin-right: 10px; }

.sec_imgaes_new {
  width: 100%;
  height: 450px;
  margin-bottom: 20px; }

.tab {
  float: left;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
  width: 30%;
  min-height: 422px; }

.tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 13px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid #d8d8d8; }

.tab button.active {
  background-color: #185dad;
  font-weight: bold;
  color: #fff; }

.tabcontent {
  float: left;
  padding: 0px 12px;
  width: 70%;
  border-left: none;
  min-height: 422px;
  padding-top: 20px; }

.proces_admission {
  width: 36px;
  float: left;
  margin-right: 21px;
  margin-bottom: 20px;
  margin-top: -5px; }

.tabcontent h3 {
  text-align: center;
  font-size: 29px;
  margin-bottom: 15px; }

.tabcontent p {
  text-align: center;
  line-height: 45px;
  font-size: 18px; }

.tabs1 {
  position: relative;
  min-height: 800px;
  clear: both;
  margin: 25px 0; }

.tab1 {
  float: left; }

.tab1 label {
  background: #185dad;
  padding: 10px;
  border: 1px solid #e5e9ff;
  margin-left: -1px;
  position: relative;
  left: 1px;
  color: #fff; }

.tab1 [type="radio"] {
  opacity: 0; }

.content {
  position: absolute;
  top: 45px;
  left: 0;
  background: white;
  right: 0;
  bottom: 0;
  padding: 20px;
  border: 1px solid #e5e9ff;
  overflow-y: auto; }

.content > * {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.6s ease; }

[type="radio"]:focus ~ label {
  ouline: 2px solid blue; }

[type="radio"]:checked ~ label {
  background: white;
  border-bottom: 1px solid white;
  z-index: 2;
  color: #000; }

[type="radio"]:checked ~ label ~ .content {
  z-index: 1; }

[type="radio"]:checked ~ label ~ .content > * {
  opacity: 1;
  transform: translateX(0);
  display: block;
  text-align: center; }

.our_strength article {
  background: #f9ebeb;
  padding: 10px 0px;
  margin-bottom: 20px;
  border: solid 1px #fdcfcf; }

.our_strength article img {
  margin: auto;
  display: block; }

.our_strength article h3 {
  text-align: center; }

.our_strength article h3 span {
  color: #c51820; }

.infra_sec artcile {
  margin: 0px; }

.infra_sec article img {
  border: solid 5px #eeeeee;
  margin-bottom: 20px;
  width: 100%;
  height: 280px; }

.proces_admission {
  width: 36px;
  float: left;
  margin-right: 21px;
  margin-bottom: 20px;
  margin-top: -5px; }

.tcsec article img {
  width: 100%;
  height: 270px;
  margin-bottom: 0px;
  object-position: center; }

.form_sec input[type="text"] {
  width: 100%;
  height: 40px;
  padding-left: 45px;
  margin-bottom: 20px;
  border: solid 1px #eee; }

.form_sec textarea {
  width: 100%;
  height: 60px;
  resize: none;
  border: solid 1px #eee;
  padding-left: 45px; }

.form_sec .fas {
  position: absolute;
  left: 10px;
  top: 10px;
  border-right: solid 1px #ddd;
  padding-right: 10px;
  height: 20px;
  color: #3c3533; }

.form_sec .text_box_sec {
  position: relative; }

.form_sec input[type="submit"] {
  width: 175px;
  height: 40px;
  margin-top: 20px;
  background: #3c3533;
  color: #fff;
  font-size: 14px;
  border: solid 1px #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
  float: right;
  text-align: center; }

.form_sec input[type="submit"]:hover {
  background: #1a428a; }

.tcsec article {
  background: #ffffff;
  border: solid 1px #ffffff;
  margin-bottom: 20px; }

.tcsec article img {
  width: 100%; }

.circular_sec h5 {
  text-align: center;
  font-size: 13px;
  text-decoration: none;
  position: absolute;
  top: 0px;
  left: 16px;
  background: #e40000;
  line-height: 25px;
  padding-top: 0px;
  padding-left: 5px;
  color: #fff;
  padding-right: 5px; }

.circular_sec h4 {
  text-align: center;
  padding-top: 15px;
  font-size: 13px;
  text-decoration: none;
  line-height: 25px;
  overflow: hidden;
  /* display: -webkit-box; */
  -webkit-line-clamp: 2;
  height: 65px; }

.circular_sec img {
  width: 35px;
  height: 35px;
  margin: auto;
  display: block; }

.circular_sec article {
  margin: auto;
  margin-bottom: 20px;
  border: solid 1px #ece2ff;
  background: #ffece2;
  padding: 10px;
  position: relative; }

.circular_sec article a {
  color: #000; }

.circular_sec article h4:hover {
  color: #a93a00; }

.message_inner article {
  box-shadow: 0px 14px 18px 19px #d4d4d447;
  width: 80%;
  margin: auto;
  padding: 40px;
  margin-top: 80px; }

.message_inner article p {
  margin: 0px; }

.message_inner article img {
  border-radius: 100%;
  width: 300px;
  height: 300px;
  margin: auto;
  border: solid 8px #eaeaea;
  margin-top: -120px;
  display: block; }

.message_inner article h3 {
  margin: 0px; }

.message_inner article h4 {
  text-align: center;
  line-height: 40px; }

.message_inner article h5 {
  text-align: center; }

.gal_inner {
  background: none !Important; }

.gal_inner h3 {
  background: #d8d8d8;
  color: #000 !important; }

.video_gal article {
  background: #efefef;
  margin-bottom: 30px;
  padding: 10px; padding-bottom: 20px;
  padding-top: 30px;
  }
.video_gal article img{width: 100%;height: 250px;}
.video_gal article .img_frame {
  background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/video_bg.png') center no-repeat;
  height: 480px;
  width: 100%;
  padding: 20px;
  margin: auto; }

.video_gal article h4 {
  margin: auto;
  margin-top: 14px;
  text-align: center;
  color: #000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 25px;
  font-size: 16px; }

.video_gal article iframe {
  width: 82%;
  margin: auto;
  display: block; }
  .sxmun_sec {
  overflow: auto;
  max-height: 800px; }
.sxmun-imun{width: 400px !important;height: 280px;margin: auto;display: block;}
.sxmun_sec h1 {
  font-size: 25px;
  text-align: center; }
.sxmun_2022_ex_brd{display:flex;align-items: center;justify-content: center;}
.sxmun_2022_ex_brd h3{font-size: 15px;text-align: center;margin: 0px;line-height: 20px;}
.sxmun_2022_ex_brd article{margin-bottom: 20px;background: #cceefe;padding: 5px;}
.sxmun_2022_ex_brd article img{width:100%;}
.sxmun_2022_ex_brd article h5{line-height: 35px;text-align: center;font-size: 15px;margin: 0px;}

.sxmun_sec p {
  font-size: 16px;
  line-height: 35px; }

.sxmun_sec_pdf h4 {
  text-align: center;
  padding-top: 15px;
  font-size: 13px; }

.sxmun_sec_pdf img {
  width: 50px !important;
  height: 50px;
  margin: 0px auto;
  display: block;
  border: none !important;
  margin-bottom: 0px !important;
  }

.sxmun_sec_pdf article {
  margin: auto;
  margin-bottom: 20px;
  border-radius: 100%;
  border: solid 1px #ece2ff;
  width: 135px;
  height: 135px;
  padding-top: 30px;
  background: #ffece2; }

.sxmun_sec_pdf article a {
  color: #000; }

.sxmun_sec_pdf article h4:hover {
  color: #a93a00; }

.sxmun_sec_new {
  position: absolute;
  z-index: 999;
  top: 300px;
  right: 10px;
  background: #fff;
  padding: 21px;
  border-radius: 100%;
  width: 130px;
  height: 130px;
  box-shadow: 0px 1px 9px 4px #085dad;
  display: none; }

.sxmun_sec_new img {
  width: 88px; }

.sxmun_img_sec_new img {
  margin-bottom: 20px;
  border: solid 5px #fff;
  object-fit: inherit;
  width: 100%; }

.sxmun_bg_clr {
  background: #eee;
  max-height: 650px;
  overflow-y: scroll;
  margin-bottom: 30px;
  min-height: 650px;
  padding-top: 10px; }

.memories_sec article {
  /* background: #eee; */
  padding: 10px;
  margin-bottom: 40px;
  border: solid 5px #ffffff;
  box-shadow: 10px 10px 10px #d2d2d2; }
  .memories_sec article h4{text-align:center;margin-top:15px;font-size: 15px;}

.memories_sec article img {
  width: 100%;
  /* border: solid 10px #ffffff; */
  height: 310px;
  }

.memories_sec article img {
  margin: 0px;
  object-fit: contain;
  background: #f1f0f0;
  object-position: center;
  }

.coming_soon {
  width: 400px !important;
  margin: auto !important;
  display: block !important;
  height: auto !important;
}

.circular_sec h5 {
  text-align: center;
  font-size: 13px;
  text-decoration: none;
  position: absolute;
  top: 0px;
  left: 16px;
  background: #185dad;
  line-height: 25px;
  padding-top: 0px;
  padding-left: 5px;
  color: #fff;
  padding-right: 5px; }

.circular_sec h4 {
  text-align: center;
  padding-top: 15px;
  font-size: 14px;
  text-decoration: none;
  line-height: 25px;
  overflow: hidden;
  /* display: -webkit-box; */
  -webkit-line-clamp: 2;
  height: 65px;
  text-transform: uppercase; }

.circular_sec img {
  width: 35px;
  height: 35px;
  margin: auto; }

.circular_sec article {
  margin: auto;
  margin-bottom: 20px;
  border: solid 1px #ece2ff;
  background: #e1f6fd;
  padding: 10px; }

.circular_sec article a {
  color: #000; }

.circular_sec article h4:hover {
  color: #a93a00; }

.countdown {
  margin-bottom: 50px;
  margin-top: 50px;
  }

.countdown ul {
  text-align: center; }

.countdown ul li {
  margin-right: 10px;
  list-style-image: none;
  list-style-type: none;
  display: inline-block;
  background: #185dad;
  padding: 30px 10px;
  color: #fff;
  font-size: 13px;
  width: 200px;
  border: 5px solid #b5d7f9;
  border-radius: 2px;
  }

.countdown ul li span {
  font-size: 35px; }

.clr{clear:both;}
.clr20{clear:both;height:20px;}
.cabi_2022{margin:0px;}
.clr_1_cabi{background: #d4fff2;}
.clr_2_cabi{background: #ffeab9;}
.clr_3_cabi{background: #ffdbdb;}
.clr_4_cabi{background: #d3ddff;}
.clr_5_cabi{background: #c4fff4;}

.cabintes_sec article {
  padding: 10px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 0px;
  }

.cabintes_sec article img {
  width: 150px;
  margin: auto;
  border-radius: 100%;
  border: solid 5px #fff;
  box-shadow: 10px 10px 10px #d2d2d2;
  display: block;
  height: 160px;
  }
.cabintes_sec h2{background: #856000;width: max-content;color: #fff;padding: 0px 10px;text-align: center;margin: auto;text-transform: capitalize;font-size: 25px;line-height: 45px;margin: 20px 0px;}
.cabintes_sec article h3 {
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  margin-top: 10px;
  background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/links.png') center no-repeat;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 30px;
  }
  .linktosec button {
    background: transparent;
}
.cabintes_sec article h4 {
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 30px;
  }

.contact_us {
  margin: 0px; }

.contact_us article {
  background: #eee;
  width: 100%;
  height: 460px;
  padding: 40px; }

.contact_us article h3 {
  color: #000;
  line-height: 25px;
  margin-bottom: 0px; }

.contact_us article p {
  margin-bottom: 35px; }

.contact_us article a {
  color: #000; }

.contact_us article a:HOVER {
  color: #0150ab; }

.contact_us img {
  width: 100%;
  height: 460px; }

.gallery_sec img {
  opacity: 1;
  display: block;
  width: 250px;
  height: 250px;
  transition: .5s ease;
  backface-visibility: hidden;
  border-radius: 100%;
  /* border-bottom-left-radius: 0px; */
  border-bottom-right-radius: 0px;
  margin: auto;
  border: solid 10px #fff3;
  object-fit: cover; }

.gallery_sec h3 {
  font-size: 15px !important;
  position: relative;
  color: #000 !important;
  background: #d8d8d8;
  line-height: 25px !important;
  padding: 5px 10px;
  z-index: 9;
  text-align: center;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 1px; }

.gallery_sec h4 {
  font-size: 15px;
  color: #fff;
  background: #518a00;
  line-height: 25px;
  padding: 5px 10px;
  text-align: center;
  font-weight: normal;
  /* display: none; */ }

.gallery_sec a {
  margin: 0px; }

.gallery_sec article {
  position: relative;
  margin-bottom: 20px; }

.gallery_sec .img_sec {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  margin-bottom: 20px; }

.gallery_sec .data_overlay_sec {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center; }

.gal_ftr_sec {
  background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/ftr.jpg') #000 no-repeat fixed;
  padding-top: 60px;
  padding-bottom: 40px;
  /* margin-top: 50px; */
  background-size: cover;
  /* background: #f2ebde; */ }

.gallery_sec {
  margin-bottom: 50px; }

.gallery_sec article:hover .img_sec {
  opacity: 1; }

.gallery_sec article:hover .data_overlay_sec {
  opacity: 1; }

.gallery_sec .readmore {
  border: solid 0px #c7c7c7;
  text-align: center;
  padding-top: 9px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  color: #ffffff;
  font-size: 20px;
  background: #ff0000;
  margin-top: 165px;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  border: solid 1px #fff; }

.assistance {
  background: rgba(68, 23, 20, 0.06);
  padding: 13px;
  border: solid 1px #ddd; }

#myImg, #myImg1, #myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s; }

#myImg:hover {
  opacity: 0.7; }

.erp_sec h4 {
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  margin-bottom: 0px;
  margin-top: 15px;
  font-weight: bold; }

.erp_sec p {
  line-height: 40px !important;
  margin-bottom: 0px; }

.erp_sec .guideheading {
  text-align: left; }

.erp_sec a {
  color: #000; }

.erp_sec img {
  width: 200px; }


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px; }

::-webkit-scrollbar-thumb {
  background: #185dad;
  border-radius: 10px; }

::-webkit-scrollbar-thumb:hover {
  background:red; }

/* ============ desktop view ============ */
.sx_msg_sec article {
  overflow-y: scroll;
  margin: 20px auto;
  padding: 20px;
  /* box-shadow: inset 8px 7px 0px 2px #ceedff; */
  border: solid 5px #ceedff;
  min-height: 250px;
  }

.sx_msg_sec article h3 {
  color: #000000 !important;
  font-size: 17px;
  background: no-repeat;
  font-weight: bold;}

.sx_msg_sec article p {
  margin: 0px; }

.sx_msg_sec article::-webkit-scrollbar {
  width: 5px; }

.sx_msg_sec article::-moz-scrollbar {
  width: 5px; }

.sx_msg_sec article::-webkit-scrollbar-thumb {
  background: #114ea9 !important;
  border-radius: 10px;
  }

.sx_msg_sec article::-moz-scrollbar-thumb {
  background: #000 !important;
  border-radius: 10px; }

.sxumn_2021_msg {
  width: 150px;
  height: 150px;
  float: left;
  margin-right: 15px !important;
  margin-bottom: 10px;
  border-radius: 100%;
  border: solid 5px #fff;
  }

.sxumn_2021_msg_secr {
  width: 300px;
  float: left;
  margin-right: 15px;
  margin-bottom: 10px;
  border: solid 5px #fff;
  }

.online_fee {
  width: 125px !important;
  border-radius: 30px;
  margin-top: 15px; }

.tab-content .fade:not(.show) {
  opacity: inherit; }

.testimonial_sec article {
  background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/quotation.png') center no-repeat #eee;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 25px;
  border-bottom: 1px solid #fff; }

.testimonial_sec article p.quotes::after,
.testimonial_sec article p.quotes::before {
  content: "\0022   ";
  font: 1.8rem/1.4 Georgia, serif; }

.testimonial_sec article h3 {
  font-size: 17px;
  color: #185dad;
  line-height: 27px;
  color: #185dad; }

.testimonial_sec article p {
  margin-bottom: 10px;
  color: #000;
  font-size: 16px;
  line-height: 31px; }

.testimonial_sec .tab-content {
  max-height: 450px;
  overflow-y: auto;
  margin-bottom: 100px; }

.testimonial_sec h1 {
  color: #fff;
  margin-bottom: 40px; }

.nav-link {
  color: #fff; }

.nav-link:hover {
  color: #fff; }
  
  /*Year Selection*/
.month-selection ul {
  text-align: center;
  padding-left: 0px; }

div[data-trigger] {
  display: none; }

.myDiv {
  display: none; }

.selected_year {
  display: block; }

#ddlYear {
  border: solid 3px #5589f7;
  color: #fff;
  height: 40px;
  padding: 5px 0px 5px 10px;
  margin-top: -10px;
  background: #2d55aa;
  font-weight: bold;
  margin-bottom: 7px;
  letter-spacing: 1px;
  width: 14.7em;
  font-size: 18px; }

.year_selction_sec select {
  margin: 0px; }

.year_selction_sec select {
  width: 100%;
  background: #c30038;
  border: solid 1px #c30038;
  height: 28px;
  color: #fff;
  margin-top: 15px; }

.myDiv {
  height: auto !important; }

.ui-tabs-vertical {
  height: auto !important;
  min-height: 500px; }

.ui-tabs-vertical .ui-tabs-nav {
  padding: .2em .1em .2em .2em;
  float: left;
  width: 13em;
  margin: 0px 15px 0px 0px; }

.ui-tabs-vertical .ui-tabs-nav li {
  clear: left;
  width: 100%;
  border-bottom-width: 1px !important;
  border-right-width: 0 !important;
  margin: 0 -1px .2em 0; }

.ui-tabs-vertical .ui-tabs-nav li a {
  display: block; }

.ui-tabs-vertical .ui-tabs-nav li.ui-tabs-active {
  padding-bottom: 0;
  padding-right: .1em;
  border-right-width: 1px; }

.ui-tabs-vertical .ui-tabs-panel {
  padding: 10px 5px 0px 50px; }

.year_selction_sec h3 {
  width: 11em;
  background: #ef2d39;
  padding: 8px 11px;
  color: #fff;
  box-shadow: -2px 5px 4px 2px #ef2d396e;
  clear: both;
  font-size: 21px;
  line-height: 27px; }

.month-selection .active_month {
  border: solid 1px #5589f7; }

.month-selection ul li {
  list-style: none;
  margin: 5px 0px;
  list-style-image: none;
  background: #6666664a;
  text-transform: capitalize;
  padding: 0px 10px;
  }

.month-selection ul {
  text-align: center; }

.month-selection {
  margin: 0px;
  float: left;
  width: 16.5em; }

.innertabdiv {
  margin-top: -55px;
  margin-bottom: 55px; }

.innertabdiv .table {
  margin: 0px; }

.innertabdiv .table tr td {
  border: 1px solid #e7c9c8;
  border-top: 1px solid #e7c9c8 !important; }

.innertabdiv .innertable.table-responsive.maintable {
  padding: 5px 20px; }

.innertabdiv .innertable {
  overflow: auto;
  color: #753400; }

.innertabdiv .innertable td {
  border: solid 1px #d9d9d9;
  padding: 5px;
  font-size: 14px;
  color: #333; }

.innertabdiv .innertable th {
  background: #054c31;
  border: solid 1px #fcf4ff;
  padding: 5px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  font-weight: normal; }

.innertabdiv .innertable tr {
  background-color: #fffbf2; }

.innertabdiv .viewbtn {
  float: right;
  background: #2d55aa;
  color: #fff;
  padding: 6px 25px;
  font-size: 16px;
  border-radius: 4px;
  margin-right: 15px; }

.innertabdiv .viewbtn:hover, .viewbtn:focus, .viewbtn:focus:active {
  color: #fff;
  outline: none; }

.innertabdiv .viewall {
  margin: 5px auto 30px auto;
  display: block;
  background: #2d55aa;
  color: #fff;
  padding: 0px 25px;
  font-size: 16px;
  border-radius: 4px; }

.innertabdiv .viewall:focus:active {
  outline: none; }

.innertabdiv a.viewpdf, .innertabdiv a.downloadpdf {
  background: #064c31;
  padding: 3px  10px;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  margin: 0px 15px 0px 0px; }

.innertabdiv .innertable td:nth-child(3), .innertable td:nth-child(4) {
  text-align: center; }

/*Inneer News CSs*/
.news-inner .year_selction_sec {
  margin-bottom: 65px; }

.singleimg {
  width: 100%;
  border: 2px solid #b1caff;
  min-height: 340px;
  height: 230px;
  max-height: 230px;
  object-fit: contain !important;
  margin-left: 15px;
  background: #ecf3f9;
  object-position: center !important;
  }

.singleimgicn {
  text-align: left;
  position: relative;
  bottom: 232px;
  left: 3px; }

.singleimgicn .bi-paperclip::before {
  transform: rotate(-196deg); }

.latestcontent h4 {
  height: 29px;
  overflow: hidden;
  }
.latestcontent p{margin-bottom: 0px;line-height: 34px;}
.latestcontentdesc {
  height: 100px;
  overflow: hidden;
  }

.latestcontent .viewbtn {
  margin-left: 13px;
  float: left; }

.latestcontent {
  padding-left: 30px; }

.rhtalign .viewall {
  float: left; }

.lftalign img {
  margin: 0px 0px 20px 0px; }

.lftalign .maininnerimg {
  border: 2px solid #b1caff;
  width: 100%;
  height: 150px;
  min-height: 180px;
  max-height: 180px;
  object-position: top;
  }

.rhtalign h4 {
  height: 40px;
  overflow: hidden;
  font-size: 18px;
  line-height: 22px;
  margin-top: 0px; }

.singleimgicn {
  text-align: left;
  position: relative;
  bottom: 232px;
  left: 3px; }

.singleimgicn i {
  position: absolute;
  background: #2d55aa;
  overflow: hidden;
  padding: 4px 7px 4px 7px;
  font-size: 18px;
  color: #fff;
  transform: rotate(45deg); }

.singleimgicn .bi-paperclip::before {
  transform: rotate(-196deg); }

.iconbox {
  text-align: left;
  position: relative;
  bottom: 174px;
  left: -6px; }

.iconbox a {
  color: #fff; }

.iconbox i {
  position: absolute;
  background: #2d55aa;
  overflow: hidden;
  padding: 4px 7px 4px 7px;
  font-size: 18px;
  color: #fff;
  transform: rotate(45deg); }

.iconbox .bi-paperclip::before {
  transform: rotate(-196deg); }

.load-data .holder, .load-data1 .holder1, .load-data2 .holder2 {
  display: block; }

.load-data .btnHolder {
  display: block;
  bottom: 0;
  width: 100%;
  margin: -40px auto;
  text-align: center;
  position: absolute;
  left: 0; }

.load-data .btnHolder button:focus {
  outline: none; }

.btnHolder .btnMore {
  display: block;
  margin: 0 auto;
  padding: 5px 20px;
  border: 2px solid #b1caff;
  background: #fff;
  color: #000; }

.btnHolder .btnMore:focus:active {
  border: 2px solid #e9821a;
  background: #fff; }

.underline {
  margin: 18px 0px;
  position: relative;
  background: #b1caff;
  width: 100%;
  height: 1px; }

.custom-btn {
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px #ffffff80, 7px 7px 20px 0px #0000001a, 4px 4px 5px 0px #0000001a;
  outline: none; }

.viewall {
  background: #2d55aa;
  background: linear-gradient(0deg, #e9821a 0%, #ef2d39 100%);
  width: 95px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none; }

.viewall span {
  position: static;
  display: block;
  width: 100%;
  height: 100%; }

.viewall:before, .viewall:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: #2d55aa;
  transition: all 0.3s ease; }

.viewall:before {
  height: 0%;
  width: 2px; }

.viewall:after {
  width: 0%;
  height: 2px; }

.viewall:hover {
  background: #fff;
  box-shadow: none; }

.viewall:hover:before {
  height: 100%; }

.viewall:hover:after {
  width: 100%; }

.viewall span:hover {
  color: #2d55aa; }

.viewall span:before, .viewall span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: #2d55aa;
  transition: all 0.3s ease; }

.viewall span:before {
  width: 2px;
  height: 0%; }

.viewall span:after {
  width: 0%;
  height: 2px; }

.viewall span:hover:before {
  height: 100%; }

.viewall span:hover:after {
  width: 100%; }

h3.headtitle {
  padding-left: 12px;
  font-size: 25px;
  font-weight: 600; }

/*Inner Gallery section*/
.image-circle {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border-top: 2px dashed #2d55aa;
  border-right: 2px dashed #996007;
  border-left: 2px dashed #996007;
  border-bottom: 2px dashed #2d55aa;
  padding: 10px;
  animation: spin 10s infinite linear;
  margin: 0px auto 20px auto; }

.image-circle img {
  animation: spin 10s infinite reverse linear;
  width: 100%;
  border-radius: 50%; }

@keyframes spin {
  100% {
    transform: rotate(1turn); } }
.galleryimg {
  width: 100%;
  height: 230px;
  object-fit: cover;
  object-position: top;
  min-height: 230px;
  max-height: 230px;
  margin: 0 auto;
  display: block; }

.innertitle {
  height: 40px;
  overflow: hidden;
  font-size: 16px;
  line-height: 23px;
  font-weight: 600; }

.innertitle, .innerdate {
  text-align: center; }

/*Pagination CSS*/
.paginationli ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.paginationli ul li {
  display: inline-block;
  width: 32%;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px; }

.showBioBtn {
  background-color: #337ab7;
  padding: 5px 10px;
  color: #fff;
  font-size: 12px; }

/*Pagination CSS*/
#page_navigation {
  clear: both;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center; }

#page_navigation a {
  padding: 3px 6px;
  border: 1px solid #2d55aa;
  margin: 2px;
  color: black;
  text-decoration: none; }

.active_page {
  background: #2d55aa;
  color: white !important; }

/*Pagination CSS End*/
/*Gallery Category page*/
.evntdate, .lastdate, .totalphoto {
  background: #2d55aa;
  color: #fff;
  display: block;
  margin: 0px auto 15px auto;
  box-shadow: 5px 4px 0px 0px #82aaff;
  border-radius: 35px 0px 35px 0px;
  padding: 7px 30px;
  width: 260px; }

.innercate .col p {
  padding: 0px 5px; }

.evntdate:hover, .lastdate:hover, .totalphoto:hover {
  color: #fff; }

.zoompic img {
  width: 100%;
  height: 322px;
  object-fit: cover;
  object-position: top;
  min-height: 322px;
  max-height: 322px;
  margin: 10px auto 20px auto;
  display: block;
  border: 5px solid #82aaff;
  border-radius: 25px 25px 25px 25px; }

.innercate .morecontent div {
  display: none; }

.innercate .morecontent a {
  text-decoration: unset;
  display: inline-block;
  color: #2d55aa;
  font-size: 21px; }

.back {
  margin: 10px auto 10px auto;
  display: block;
  text-align: center; }

.back a {
  background: #2d55aa;
  color: #fff;
  padding: 1em 1.3em;
  border-radius: 50%;
  text-align: center; }

.innercate .col h4 {
  text-transform: uppercase;
  padding: 31px 0px 5px 0px !important;
  font-weight: 600; }

.morecontent span {
  display: none; }

.morelink {
  display: block; }

/*Magnify pop up*/
.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: #000 !important; }

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9; }

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden; }

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid white; }

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%; }

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center; }

.magnify-footer .magnify-toolbar {
  display: inline-block; }

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #af251b; }

.magnify-button:hover {
  color: #fff;
  background-color: #e00000; }

.magnify-button-close:hover {
  background-color: #e00000; }

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: #000;
  color: #333; }

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: #00000080 #00000080 #00000080 #ffffff80;
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear; }

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden; }

@-webkit-keyframes magnifyLoading {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0); }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0); } }
@keyframes magnifyLoading {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0); }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0); } }
:full-screen {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important; }

:full-screen .magnify-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: #000; }

:-webkit-full-screen {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important; }

:-moz-full-screen {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important; }

:-ms-fullscreen {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important; }

:fullscreen {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important; }

:-webkit-full-screen .magnify-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: #000; }

:-moz-full-screen .magnify-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: #000; }

:-ms-fullscreen .magnify-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: #000; }

:fullscreen .magnify-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: #000; }

.magnify-toolbar.magnify-foot-toolbar button i {
  color: #fff;
  font-size: 20px; }

/*Magnify pop up*/
/*Inner Event section*/
.events-sec .date {
  line-height: 15px;
  margin-bottom: 10px !important; }

.events-sec p span i {
  color: #2d55aa;
  padding-right: 5px; }

.events-sec p {
  margin-bottom: 10px !important;
  font-size: 14px; }

.events-sec span .eve-time-loc {
  padding-right: 10px; }

.events-sec .load-data .btnHolder {
  display: contents; }

/*Inner Event section*/
/*Inner highlights*/
.iner-highlight p a {
  background: #2d55aa;
  padding: 5px 5px;
  color: white; }

.news-inner p.date {
  font-size: 14px; }

.iner-highlight p {
  margin-bottom: 10px !important; }

.load-data {
  position: relative; }
.card-body {
    padding: 7px;
text-align: center!important;}

.slider-bottom .card {
    box-shadow: 1px 1px 17px 7px #818181;
}

.btn-success {
    color: #fff;
    background-color: #ffffff;
    border-color: #5f901900;
    padding: 5px;
}
.new_menu_link{width: 37px;display: block;position: absolute;right: 0px;top: -10px;}
      .blink_new_menu {
  
    border-bottom: 2px solid #000;
    -webkit-animation: test 1s infinite; /* Chrome, Safari, Opera */
    animation: test 1s infinite;
}
/* Chrome, Safari, Opera */
@-webkit-keyframes test {
    50% {border-bottom: 2px solid yellow;}
}
/* Standard syntax */
@keyframes test {
    50% {border-bottom: 2px solid red;}
}

.gallery-new{margin:0px;}
.gallery-new article{width: 100%;padding: 10px;background: #115087;padding-top: 20px;position: relative;}
.gallery-new article img{width: 100%;height: 250px;object-fit: cover;}
.gallery-new article h3{font-weight: 100;font-size: 15px;text-align: center;line-height: 50px;color: #fff;margin-bottom: 0px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;}
.gallery-new .link{position: absolute;background: #fff700;bottom: 60px;right: 10px;padding: 5px 10px;border-radius: 5px;display: none;}

.video-arti .link{position: absolute;background: #fff700;bottom: 60px;right: 10px;padding: 5px 10px;border-radius: 5px;display: block;}
.submenu_right{right:100%;left: inherit !important;}
.gallery-new .link:hover{background: #ffffff;}
.gallery-new .link i{font-weight:bold;font-size: 20px;color: #000;}
.gallery-new h1{margin-bottom:50px;    font-weight: 100;}
.gallery-new .video-arti{background: #b5d7f9;}
.gallery-new .video-arti h3{color:#000}
    .gallery-new i {color:#000;
    }
 
.online-reg h3{font-size: 19px;margin: 0px;}
.online-reg h3 a {color:#fff;text-decoration: none;}
.online-reg i{color: #ffffff; width: 37px; height: 30px; border-radius: 100%;  }
.online-reg img{
  width: 75px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0px;
  background: #f3ff27eb;
 }
 @keyframes bounce1 { 
  0% {
    background-color: #e92819;
} 
33% {
    background-color: #8b120a;
}
66% {
    background-color: #e92819;
}
100% {
    background-color: #8b120a;
}
}
.bounce1 { border-radius: 0px 25px 25px 0px; color: #fff; font-size: 14px; font-weight: 700; text-align: center; animation: bounce1 1s infinite; padding: 10px 12px; }
li.OnlineR {
  position: absolute;
  bottom: -50px;
  width: max-content;
  right: 0;
}

li.OnlineR a {
  color: #fff;
}

.OnlineR span.bounce1 {
  border-radius: 25px 0px 0px 25px;
  padding: 10px 20px;
}
.new_slid { position: relative; }
.new_slid .MS-content {white-space: nowrap;overflow: hidden;}
.new_slid .MS-content .item{display:inline-block;position: relative;vertical-align: top;overflow: hidden;/* min-height:450px; *//* height: 100%; */white-space: normal;padding: 0 10px;float:none;} 
.new_slid .MS-controls button {border: none;background-color: transparent;outline: 0;font-size: 50px;top: 0px;color: rgba(0, 0, 0, 0.4);transition: 0.15s linear;cursor: pointer;}
.new_slid .MS-controls{position:absolute;right: 10px;top: -80px;cursor: pointer;}
.new_slid .MS-controls .MS-left { left: 0px; } 
.new_slid .MS-controls .MS-right { right: 0px; } 
#basicSlider .MS-controls .MS-left { top: 35px; left: 10px; }
#basicSlider .MS-controls .MS-right { top: 35px; right: 10px; }
.MS-controls i{border: solid 1px #da261d;width: 30px;height: 30px;color: #da261d;font-size: 20px;padding-top: 4px;border-radius: 100%;}
.MS-controls i:hover{background: #ee2022;color: #fff;}
.nav-item{list-style-image: inherit !important;}
.toppers-inner{margin:0px;}
.toppers-inner article{background: #d4e5f5;padding: 10px;border: solid 1px #99cbfd;margin-bottom: 20px;}
.toppers-inner article img{margin: auto;width: 150px;height: 150px;border-radius: 100%;margin-top: 20px;display: block;} 
.toppers-inner article h3{text-align: center;font-size: 15px;line-height: 25px;margin: 0px;margin-top: 10px;} 
.toppers-inner article p{text-align:center;}
.toppers-inner article h4{font-size: 15px;text-align: center;} 
.toppers-inner article h5{margin:0px;background: #185dad;width: 55px;color: #fff;font-size: 15px;text-align: center;line-height: 25px;} 
.toppers-inner #ddlYear{float:right;width: 150px;margin-bottom: 30px;margin-top: 10px;background: #eeeeee;border: solid 1px #eee;color: #000;font-size: 15px;}

.highlights .iconbox{margin:0px;}

button {
   position: relative;
   display: inline-block;
   cursor: pointer;
   outline: none;
   border: 0;
   vertical-align: middle;
   text-decoration: none;
   font-size: inherit;
   font-family: inherit;
}
 button.learn-more {
   font-weight: 600;
   color: #382b22;
   text-transform: uppercase;
   padding: 5px 15px;
   background: #fff0f0;
   border: 2px solid #b18597;
   border-radius: 0.75em;
   transform-style: preserve-3d;
   transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
}
 button.learn-more::before {
   position: absolute;
   content: '';
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: #d1adbc94;
   border-radius: inherit;
   /* box-shadow: 0 0 0 2px #b18597, 0 0.625em 0 0 #ffe3e2; */
   transform: translate3d(0, 0.75em, -1em);
   transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}
 button.learn-more:hover {
   background: #ffe9e9;
   transform: translate(0, 0.25em);
}
 button.learn-more:hover::before {
   box-shadow: 0 0 0 2px #b18597, 0 0.5em 0 0 #ffe3e2;
   transform: translate3d(0, 0.5em, -1em);
}
 button.learn-more:active {
   background: #ffe9e9;
   transform: translate(0em, 0.75em);
}
 button.learn-more:active::before {
   box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
   transform: translate3d(0, 0, -1em);
}
 .schoolparliament {
    padding: 0px 110px;
}
.verticaltabs .nav-pills .nav-link.active {
  background: #185dad;
  color: #fff;
}
.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 0.25rem;
    background: #b5d7f9;
    margin: 0px 5px;
    padding: 2px 12px;
    color: #000000bd;
}
#sidebar a.active {
  color: #ff0000;
}
.nav-tabs li button {
  color: #4e4e4e;
}
.verticaltabs {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.verticaltabs .tab-content {
  padding: 0px 12px;
  width: 70%;
  border-left: none;
  min-height: 422px;
  padding-top: 20px;
}

.verticaltabs .nav-pills {border: 1px solid #eeeeee;background-color: #eeeeee;width: 30%;min-height: 422px;}

.verticaltabs .nav-pills .nav-link {
  display: block;
  background-color: inherit;
  color: black;
  padding: 13px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid #d8d8d8;
  margin: 0px 0px;
  border-radius: 0;
}
.verticaltabs h3 {
  font-weight: 500;
  text-align: center;
  font-size: 29px !important;
  margin-bottom: 15px;
}
.verticaltabs p {
  text-align: center;
  line-height: 45px;
  font-size: 18px;
}
.menu-sec .navbar-collapse ul .dropdown-item.active  {
  background: #e9ecef;
  color: #212529;
}
.SXMUN .nav-pills .nav-link.active{
  background :#e11f25;
  }
  
.backDiv {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(3px);
  background-size: 100% 100% !important;
}

.row.tabs-dynamic {
  background:#edf6ff;
  padding: 10px;
  margin: 0px 0px 40px 0px;
}

.row.tabs-dynamic p {
  margin-bottom: 0;
}

.row.tabs-dynamic select {
  width: 100%;
  border: 0px;
  background: transparent;
  padding: 8px 5px;
  border-bottom: 1px solid #b6b6b6;
}

.row.tabs-dynamic input {
  padding: 9px 5px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #b6b6b6;
  background: transparent;
}

.count-val p {
  margin-bottom: 0px;
}

.gallery-inner {
  background:linear-gradient(88deg, #b5d7f9, #02488f);
  margin-bottom: 30px;
  box-shadow: 7px 6px 12px -4px #ddd;
  /* position: relative; */
  overflow: hidden;
  transition: all 0.5s ease-in;
}
.gallery-blk a {
  color: inherit;
}
.gallery-content {
  padding: 20px 15px;
  position: relative;
  background: #fff;
  width: 96%;
  margin: -30px auto 10px auto;
}

.gallery-blk img {
  width: auto;
  max-width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
}

.gallery-inner:hover .gallery-content {
  bottom: 0;
}
.gallery-blk {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-gallerycount .gallery-blk img {
  height: 100%;
}
.sub-gallerycount .gallery-blk {
  height: 200px;
  margin-bottom: 20px;
}
.gallery-blk .gallery-title h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.gallery-title h3 {
  font-size: 18px;
  color: #d19f09;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
}
a.view-more {
  color: var(--black);
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}
.gallery-title p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 28px !important;
  font-size: 16px !important;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.gallery-date {
  display: flex;
}

.gallery-date h6 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 25px;
  background: #fc097c;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}
.gall-num h5 {
  background: transparent;
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 25px;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}
.years {
  display: none;
}

.showYear {
  display: flex;
}

.load-more .next {
  background: #ec2024;
  width: max-content;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  margin: auto;
  margin-top: 20px;
}
.gallery-title {
  font-size: 20px;
  line-height: 30px;
  font-family: 'Poppins', sans-serif;
}
.galleryCount:nth-child(2n+2) .gallery-inner .gallery-date h6 {
  background: #f9d464;
}
.galleryCount:nth-child(2n+3) .gallery-inner .gallery-date h6 {
  background: #00acee;
}
.galleryCount:nth-child(2n+4) .gallery-inner .gallery-date h6 {
  background: #198754;
}
.newsbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 2px 0px 8px 0px #ccc;
  margin-bottom: 20px;
}

.newsboximg {
  width: 40%;
  margin-right: 10px;
}

.newsboxdesc {
  width: 58%;
}
.newspgh {
  height: 150px;
  overflow-y: scroll;
  padding-top: 15px;
  margin-top: 10px;
  padding-right: 10px;
}
.uniformimg {
  height: auto;
  margin-bottom: 20px;
}
.newsboximg img {
  border: 10px solid #fff;
  height: 190px;
  object-fit: contain;
  background: #f1f1f1;
  width: 100%;
}
.newsdate {
  display: flex;
  justify-content: space-between;
  padding: 8px 5px;
}
.newspgh p {
  font-size: 15px;
  line-height: 30px;
}
.newsdate a {
  background: #6a92bb;
  border-radius: 5px 3px 6px 0px;
  color: #fff;
  padding: 2px 5px;
}
.newsdate span {
  font-size: 14px;
  line-height: 30px;
}
.event-logo {
  height: 320px!important;
}
i {
  position: relative;
  top: -2px;
}
.newspgh h5 {
  font-family: 'Roboto',sans-serif;
  color: #000000ad;
  line-height: 30px;
  font-size: 18px;
}
.newspgh::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.newspgh::-webkit-scrollbar-thumb {
  background-color: var(--maroon);
}
.newspgh::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
}

.chooseyear { margin-bottom: 40px;}
#chooseddlYear{
    border: unset;
    color: #000;
    height: 30px;
    padding: 0px 0px 0px 4px;
    background: unset;
    font-weight: bold;
    margin-bottom: 15px;
    letter-spacing: 1px;
    width: 6.5em;
    font-size: 15px;
    float: right;
    margin-top: 30px;
    border-bottom: 1px solid grey;
    }

#chooseddlYear option { color: black;}
.chooseyear  select{margin:0px;}
.chooseyear  select{width:100%;background: unset;border: unset;height:28px;color:#000;margin-top:15px;border: unset;border-bottom: 1px solid grey;}
.clr{clear:both}
.examresultboximg {width: 70px;margin-right: 15px;background: #fff;padding: 10px;border-radius: 5px;border: 2px solid #080e41;}

.examresultbox {
    width: calc( 25% - 15px);
    border: 4px solid #fff;
    border-radius: 5px;
    padding: 10px;
    background: #b5d7f94d;
    box-shadow: 0px 0px 3px 1px #b0b6df;
    margin: 10px 15px 6px 0px;
}
.examresultbox a {
    display: flex;
    align-items: center;
}
.examresultbox a:hover {
  color: inherit;
}
.examresultboxdesc h4 {
    font-size: 17px;
    margin-bottom: 5px;
}
.examresult {
    display: flex;
    flex-wrap: wrap;
}
.examresultboxdesc p {
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 0;
}

.examresultboxdesc p span {
  display: block;
  color: #ec2024;
}

.examresultboxdesc {
  display: flex;
  flex-direction: column;
}
.clr{clear:both}
.video-gallery {
  padding: 20px;
  box-shadow: 0px 0px 4px 0px #03030330;
  margin-bottom: 20px;
  border-radius: 4px;
  background: #dbedff;
}
.video-gallery a {
 
  color: inherit;
  align-items: center;
}
.video-gallery a i {
 font-size: 40px;
 color: #ad0807;
 margin-right: 10px;
 display: flex;
}
.video-gallery a h3 {
  position: static;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 100;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 0;
  padding-left: 0;
  background: transparent;
  -webkit-text-fill-color: unset;
  font-family: 'Poppins';
}
.video-gallery p {
  font-size: 12px;
  margin-bottom: 0;
}
.video-gallery a h3:after {
  position: static;
}
.video-gallery a img {margin:auto;display:block;
  width: 100%;margin-bottom:20px;height:200px;
  
}
.news-head {display: flex;align-items: center;}
.news-head h2 {    padding-right: 20px; border-right: 1px solid #c5c5c5;  margin-right: 20px;  background: #e5e5e5; padding: 4px 10px;}
.news-head h3 {
    font-weight: 400;
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 14px;
    color: #00599b;
    position: relative;
}
.news-blk {margin-bottom: 20px;padding-right: 30px;padding-bottom: 30px;width: 97%;box-shadow: 9px 11px 5px 0px #dddddd3d;position: relative;overflow: hidden;padding: 20px;box-shadow: 0px 0px 12px 0px #e7e7e7;border: 4px double #b5b5b5;transition: 0.5s;}
.news-blk:before {
    content: "";
    position: absolute;
    top: -40px;
    right: -8px;
    width: 100px;
    height: 100px;
    background: #ecf9ff;
    border-radius: 50%;
}
.attachment {
    background: #00bcd4;
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    padding: 5px;
    }
.news-content p {margin-bottom: 6px;max-height: 160px;overflow-y: scroll;}
.news-content p::-webkit-scrollbar {width: 0;}
.news-blk:hover {
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 1px, rgb(0 0 0 / 5%) 0px 4px 2px, rgb(0 0 0 / 5%) 0px 8px 4px, rgb(0 0 0 / 5%) 0px 16px 8px, rgb(0 0 0 / 5%) 0px 32px 16px;
}
.news-head h2 span {
    display: block;
    color: #153168;
    font-size: 18px;
    text-align: center;
}
.newsCount:nth-child(4n+1) > div {
    border-left: 3px solid #fff27e;
}
.newsCount:nth-child(4n+2) > div {
    border-left: 3px solid #ceb2ff;
}
.newsCount:nth-child(4n+3) > div {
    border-left: 3px solid #ff928f;
}
.newsCount:nth-child(4n+4) > div {
    border-left: 3px solid #ffb470;
}
.achievementacc button {
  padding: 10px;
  background: #ebf5ff;
}
.accordin-img img {
  width: 100%;
}
.achievementacc button p {
  line-height: 20px;
  font-size: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.achievementacc button:focus {
  box-shadow: none;
}
.achievementacc .accordion-button:not(.collapsed) {
  background: #b5d7f9;
}
.achievementacc h2 {
  padding: 0;
  margin: 0;
}
.achievementacc h4 {
  font-size: 20px;
  line-height: 30px;
}
.accordin-img {
  float: left;
  margin-right: 20px;
  margin-bottom: 15px;
  width: 370px;
}

.achievementdesc {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.achievementdesc p {
  padding: 0px 16px 3px 2px;
  color: #ec2024;
  font-size: 14px !important;
  line-height: 30px !important;
}
.achievementacc p {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 30px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link { 
  background-color: #e11f25;
}
.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}
@media all and (max-width: 1600px) {
  .about-sec-data img {
    height: auto;
    width: 350px;
    bottom: 0; }

  .about-sec-patron img {
    width: 230px;
    height: auto;
    bottom: 0;
    position: relative;
    left: auto; } }
@media all and (max-width:1400px) {
  .examresultbox {
    width: calc( 34% - 24px);
  }
.schoolparliament {
    padding: 0px 50px;
}
  .message-sec p{-webkit-line-clamp:7;height: 175px;}.message-sec h1{height:65px;}.navbar-dark .navbar-nav .nav-link{font-size: 14px;}
  .about-sec-data img {
    position: inherit;
    height: auto;
    object-fit: cover;
    right: 0px;
    bottom: auto;
    width: 90%;
    display: block; } }
@media all and (max-width: 1300px) {
  .events-sec h1 {
  color: #fff;

  }
  .topopers-sec .card {
    margin: auto;
    border-radius: 0px; }

  .news {
    background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/news-sec.jpg') repeat; }
    .news-section h1 {
      color: #fff; } }
@media all and (max-width: 1199px) {
  .newspgh { 
    padding-left: 15px;
}
  .newsboxdesc {
    width: 100%;
}
 .newsboximg {
    margin-right: 0px;
    width: 100%;
}

  .topopers-sec button.learn-more{margin-top:30px;margin-bottom:30px;}.gallery .card{margin-top: 40px;}.gallery { background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/news-sec.jpg') repeat; }.notice-color .notice-sec .card h1{text-align:left;margin-left:20px;}
  .topopers-sec .card {
    margin: 10px auto; }
.dropdown-item{font-size:13px;}
  .topopers-sec h1 {
    margin-top: 20px;
    margin-bottom: 10px; }

  .events-sec {
    background: url('https://webapi.entab.info/api/image/SXSBPB/public/Images/event-sec.jpg') repeat; }

  .message-sec .principal-msg {
    height: 300px; }
    .message-sec .principal-msg p {
      /* color: #fff; */
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      }

  .news .card {
    margin-bottom: 20px; }

  .news .birthday-sec h1 {
    margin-bottom: 20px;
    margin-top: 20px; }
                                    
.mascot img {
    width: 120px;
}

  .about-sec-data {
    padding: 10px; }
    .about-sec-data img {
      position: inherit;
      width: auto;
      display: block;
      margin: auto;
      max-width: 100%; }
    .about-sec-data h5 {
      text-align: center; }

  .about-sec-patron {
    padding: 10px; }
    .about-sec-patron img {
      position: inherit;
      width: auto;
      display: block;
      margin: auto;
      max-width: 100%; }
    .about-sec-patron h5 {
      text-align: center; }
     

      
      
  .slider-sec .carousel-caption {
    display: none !important; }

  .menu-sec {
    background: #ffffff;
    text-align: left;
    }

  .navbar-dark .navbar-nav .nav-link {
    color: #000;
    font-weight: normal;
    text-align: left;
    font-size: 13px;
    padding: 0px 2px;
    }

  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #2d55aa; }
 .notice-color .notice-sec marquee,.notice-color .notice-sec  {
    height: 400px;
}
.examresultbox {
  width: calc( 50% - 15px);
}                     
}
@media all and (max-width: 992px) {
 .alumni-reg ul { 
    position: fixed;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: #000;
    font-weight: normal;
    text-align: left;
    font-size: 15px; }

  .events-sec .card {
    margin-bottom: 20px; }

  .slider-bottom .card {
    margin-bottom: 20px; box-shadow: 1px 1px 8px 0px #81818138;}

  .message-sec .right-arrow {
    display: none; } }
.logo-sec-contact {
  text-align: center; }

@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative; }

  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px; }

  .nav-item .submenu-left {
    right: 100%;
    left: 0; }

  .dropdown-menu > li:hover {
    background-color: #f1f1f1; }

  .dropdown-menu > li:hover > .submenu {
    display: block; } }
@media (max-width: 991px) {.menu-sec ul li i {margin-right: 15px;}.sec_imgaes_new {height:200px;}.important-links{margin-top:0px;}.about-sec-patron img{margin-top:25px;}.about-sec p{height:auto;width: 85%;margin: auto;}button.learn-more{margin:auto;display:block;}.logo-sec ul{text-align: center;margin-top: 10px;}.online-reg{position: inherit;}.navbar-dark .navbar-nav .nav-link{padding: 10px 10px;font-size: 15px;}
  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: .5rem; } }
@media (max-width: 767px) {
  .accordin-img {
    float: none;
    margin-right: 0;
    width: 100%;
}
  .examresultbox {
    width: 100%;
    margin-right: 0;
}
  .verticaltabs .nav-pills{width:100%}
  .verticaltabs .tab-content{width:100%}
  .mascot img {
    width: 90px;
}.inner_slider_img{height:200px;}.inner_slider .bread_cumb h2{font-size:21px;padding-top: 20px;margin-bottom: 0px;}.inner_slider .bread_cumb{height:200px;padding: 10px 0px;}.message_inner article{padding:10px;width:100%;}.inner_cont strong, .inner_cont p{font-size:16px;line-height:25px;}h1, .h1{font-size:25px;}.inner_cont{padding:40px 10px;}.message-sec h1{font-size:18px;}
  .modal-dialog {
    width: 96%;
    min-width: inherit; }

  .modal-dialog img {
    height: 350px; }

  .alumni-reg {
    /* display: none; */ }

  .mainsld-overlay-content img {
    width: 100px; }
  .mainsld-overlay-content h1 {
    font-size: 30px; }

  .dropdown {
    width: 100%; }

  .about-sec-data {
    padding: 10px; }
    .about-sec-data img {
      position: relative;
      margin: auto;
      display: block; } 
  .alumni-reg img.alumni {margin-top: 195px;}
 }
@media (max-width: 576px) {.important-links .card-img.large{height:210px;}.notice-color .notice-sec{height: 314px;}.slider-sec img, .slider-sec{height:auto;}.slider-bottom{margin-top: 30px;}
  .footer-sec img {
    margin: auto;
    float: none;
    display: block; }
  .footer-sec .card h5 {
    text-align: center; }
  .footer-sec .card p {
    text-align: center;
    width: auto; }
  .footer-sec .card .list-inline {
    text-align: center; }

  .gallery-sec .card-img.large {
    height: 250px; }

  .modal-dialog img {
    height: 200px; } }
/* ============ small devices .end// ============ */
/*# sourceMappingURL=style.css.map */
.magnify-modal{position: fixed!important;z-index: 999999999!important;padding-top: 10px!important;left: 0!important;top: 0!important;width: 100%!important;height: 100%!important;overflow: auto!important;background-color: rgba(0, 0, 0, 0.8509803921568627)!important;}
.magnify-header .magnify-toolbar {float: right;position: relative;z-index: 9;}
.magnify-stage {position: absolute; top: 40px; right: 10px; bottom: 40px; left: 10px; z-index: 1; background: #0000000a; overflow: hidden;}
.magnify-image {position: relative; display: inline-block; border: 5px solid white;}
.magnify-image.image-ready {max-width: 100%; max-height: 100%;}
.magnify-footer {position: absolute;bottom: 0;z-index: 2; width: 100%;height: 40px;color: #fff;text-align: center;}
.magnify-footer .magnify-toolbar {display: inline-block;}
.magnify-button {display: inline-block;width: 40px;height: 40px;-webkit-box-sizing: border-box;box-sizing: border-box;margin: 0;padding: 10px;font-size: 16px;color: #fff;text-align: center;border-width: 0;border-radius: 0;cursor: pointer;outline: none;background: #fff;}
.magnify-button:hover {color: #fff;    background-color: #e00000;}
.magnify-button-close:hover {background-color: #e00000;}
.magnify-loader {position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;text-align: center;background-color: rgba(0, 0, 0, 0.3);color: #333;}
.magnify-loader::before {content: ''; display: inline-block; position: relative;width: 36px; height: 36px; -webkit-box-sizing: border-box; box-sizing: border-box; border-width: 5px; border-style: solid; border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
border-radius: 100%; -webkit-animation: magnifyLoading 1s infinite linear; animation: magnifyLoading 1s infinite linear;}
.magnify-loader::after {content: ''; display: inline-block; width: 0; height: 100%; vertical-align: middle; overflow: hidden;}
@-webkit-keyframes magnifyLoading {0% {-webkit-transform: rotateZ(0deg) translate3d(0, 0, 0); transform: rotateZ(0deg) translate3d(0, 0, 0);}  100% {
-webkit-transform: rotateZ(360deg) translate3d(0, 0, 0); transform: rotateZ(360deg) translate3d(0, 0, 0);}}
@keyframes magnifyLoading {0% {-webkit-transform: rotateZ(0deg) translate3d(0, 0, 0); transform: rotateZ(0deg) translate3d(0, 0, 0); }
  100% {-webkit-transform: rotateZ(360deg) translate3d(0, 0, 0); transform: rotateZ(360deg) translate3d(0, 0, 0); }}
:full-screen {top: 0 !important; left: 0 !important; width: 100% !important; height: 100% !important;}
:full-screen .magnify-stage {top: 0; right: 0; bottom: 0; left: 0; border-width: 0; background-color: #000;}
:-webkit-full-screen {top: 0 !important; left: 0 !important; width: 100% !important; height: 100% !important;}
:-moz-full-screen {top: 0 !important; left: 0 !important; width: 100% !important; height: 100% !important;}
:-ms-fullscreen {top: 0 !important; left: 0 !important; width: 100% !important; height: 100% !important;}
:fullscreen {top: 0 !important; left: 0 !important; width: 100% !important; height: 100% !important;}
:-webkit-full-screen .magnify-stage {top: 0;right: 0;bottom: 0;left: 0; border-width: 0; background-color: #000;}
:-moz-full-screen .magnify-stage {top: 0; right: 0; bottom: 0; left: 0; border-width: 0; background-color: #000;}
:-ms-fullscreen .magnify-stage {top: 0;right: 0;bottom: 0;left: 0; border-width: 0;background-color: #000;}
:fullscreen .magnify-stage {top: 0; right: 0; bottom: 0; left: 0; border-width:0;background-color: #000;}
.magnify-toolbar.magnify-foot-toolbar button i{color: #000;font-size:20px;}
button.magnify-button.magnify-button-close {color: #000;}
.highlight_sec{margin:0;background:#085dad;height:28px}
.highlight_sec p{color:#fff;}
.highlight_sec p i{color:#fff;}
.highlight_sec h4{position:absolute;color:#ffd400;background:#e30b13;line-height:25px;padding:0 3px;z-index:9;font-weight:400;font-size:15px;border-right:solid 1px #fff;font-weight:bold;animation:blinker 2s linear infinite;color:#ffd400;border-right:solid 1px #fff;/* display:none */height: 28px;}
@keyframes blinker {50%{color:#fff;background:#085dad}}
.back-btn{float: right;background: #2d55aa;padding: 10px;}
.back-btn a{color: #fff;}
.back-btn:hover{border-radius:10px;}
.back-btn i{margin-right: 5px;}
.yeartab .nav-pills .nav-link{color: #383737; background:#e3dada; border: solid 1px #bbb7b7; margin: 4px; padding:5px 20px 5px 20px; }
.yeartab  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {color: #fff !important;background-color: #0d6efd !important;}
.media_sec article{padding:15px;background:#eee;border:solid 1px #dedede;margin-bottom:25px}
.media_sec article img{width:100%;height:220px}
.media_sec article h4{    font-size: 21px;overflow:hidden;display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient:vertical;height: 50px;margin-top:10px;margin-bottom: 20px;}
.media_sec article p{line-height: 20px;font-size: 15px;}
.media_des{overflow:hidden !important;display:-webkit-box !important;-webkit-line-clamp: 6 !important;-webkit-box-orient:vertical !important;height:55px !important;}
.voice-sec img{width:50px !important;height:auto !important;}
@media (max-width:1199px){.sx_msg_sec article h3{line-height:30px;}.inner-events-sec .myDiv p{line-height:25px;margin-bottom: 10px;font-size: 16px;}.latestcontent p{line-height: 27px;font-size: 17px;height: auto;}.latestcontent h4{margin-top:20px;font-size: 20px;}.innertabdiv{margin-top: 0px;} .month-selection ul li{display: inline-block; width: 53px;} .month-selection{width: 100%;}.year_selction_sec h3{width: 100%;}#ddlYear{width:100%;} }
@media (max-width:991px){.countdown ul li {
    width: 147px;
    margin-right: 0px;
  }
  .sx_msg_sec {
    height: auto;margin-bottom:10px;}
  
  
  .inner_cont h2{font-size:24px;}.sxmun_img_sec_new img{margin:auto;display:block;margin-bottom:20px;}.inner_cont h3{line-height:25px;}.sxumn_2021_msg{float:none;margin: auto !important;display:block;margin-bottom:20px;}.sxumn_2021_msg_secr{float:none;margin:auto;display: block;}.mobile_app h5{text-align:center;}.mobile_app h5{text-align:center;}.menu-sec{background: #ffffff;}.paginationli ul li{width: 48%;}}
@media (max-width:767px){
  .countdown ul li {
        padding: 10px 10px;
    margin-right: 0px;
    border: 0;
    border-radius: 0;
}
  .countdown ul li span {
    display: block;
}
.countdown ul {
    display: flex;
        padding-left: 0;
  }
  .memories_sec article audio{width:100%}.memories_sec article img{height:200px;}.message_inner article img{width:200px;height:200px;}.inner_cont ul li{margin-bottom: 10px;font-size: 16px;}.tabcontent, .tab{float:none;width:100%}.message-sec h1{height:auto;}.our_strength article img{width:100px;}.our_strength article h3{font-size:17px;}.kids-link h5{line-height: 25px;padding-top: 5px;}.kids-link img{/* display:none; */padding: 0;}.notice-color .notice-sec, .notice-color .notice-sec marquee{height:250px;}.paginationli ul li{width: 48%;}}
@media (max-width: 576px){
  .alumni-reg img.sxmun-2022 { 
    width: 160px;
}
  
  .cabintes_sec article img{width:100px;height:100px;}.cabintes_sec article h3, .cabintes_sec article h4{font-size:13px;}.slider-bottom .col{width:50%;flex: inherit;}.inner-events-sec .myDiv p{font-size:15px;}.innertabdiv .col-xs-6 {width: 100%;} .lftalign img{margin: 0 auto;}.rhtalign , .rhtalign .date{text-align: left;}.rhtalign .viewall {float: none;} .load-data .btnHolder{margin: 0 auto; position: static;} .viewbtn{margin: 0 auto; float: none; display: block;} .iconbox{ bottom: 150px;} .rhtalign h4{margin-top: 0px;text-align: left;margin-bottom: 15px;} .singleimg{margin-left: 0px;} .singleimgicn{left: -5px;}}
.tabcol .nav-link {
    background: none;
    border: 0;
    border-radius: 0.25rem;
    background: #a9a1a1;
    margin: 0px 5px;
    padding: 2px 12px;
}

tr.tablebg {
  background: #daf6ff;
}